.gallery {
    position: relative;

    &-back-icon {
        font-size: 2em !important;
        position: absolute;
        left: 0;
        margin: 1em;
        top: 0;
    }

    &-pictures-container {
        width: 95%;
    }

    &-description {
        width: 60%;
    }
}