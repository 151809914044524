.centered {
    display: flex;

    &-h {
        @extend .centered;
        justify-content: center;
    }

    &-v {
        @extend .centered;
        align-items: center;
    }

    &-h-v {
        @extend .centered-h;
        @extend .centered-v;
    }
}