@import '../constants.scss';

.App {
  text-align: center;
  color: rgba(0,0,0,0.87);
  line-height: 1.5;
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  font-family: $font_default;
}

html {
    scrollbar-width: thin;
    overflow-y: scroll;
}

.grecaptcha-badge {
  display: none;
}

.app-content {
  flex-grow: 1;
  min-width: 500px;
  margin-left: 250px;
}