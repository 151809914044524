@import '../constants.scss';

.portfolio {
        padding-top: 64px;
    .home {
        &-item {
            margin: 0.5em;
            width: 600px;
            height: 350px;
            font-family: $font_title;
            font-size: 3em;
            color: white;
            transition: opacity 0.2s, transform 0.3s;
            opacity: 0.9;

            &:hover {
                box-shadow: 0px 2px 4px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
                z-index: 100;
                transform: scale(1.1, 1.1);
                opacity: 1;
                transition: opacity 0.5s, transform 0.5s, box-shadow 0.5s;
            }
        }
    }

    .menu {
        &-item {
            padding: 0 0.5em;
            line-height: 0.5em;
            color: white;
            min-height: 64px;
            transition: background-color 0.3s;
            font-family: $font_title;
            font-size: 2em;
            &.active, &:hover {
                transition: background-color 0.3s;
                background-color: $menu_background_color_light
            }
        }
    }
}