.entities-container {
    width: 20%;
}

.entity-container {
    margin: 0.5em;
    width: 100%;
}

.list-container {
    display: flex;
}

.add-icon {
    margin: 0.5em 0;
}