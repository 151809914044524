.slider {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.slider-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.slide {
    width: 100%;
    height: 100%;
    flex: 0 0 100%;
}

.slide-infos {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.slide-title {
    color: white;
}

.slide-infos-button {
    color: white;
}

.arrow {
    display: flex;
    color: white;
    position: absolute;
    top: 50%;
    z-index: 999;
}

.arrow-left {
    left: 1em;
}

.arrow-right {
    right: 1em;
}