.form-line {
    display: flex;
    margin: 0.5em 0;
    align-items: center;
    justify-content: center;
}

.MuiTextField-root, .MuiFormControl-root, .form-field {
    flex: 1;
    margin: 0 0.5em !important;
}

.buttons-form-action {
     margin: 0.5em 0;
     
     :nth-child(n) {
        margin: 0em 0.5em;
     }
}

form {
    margin: 1em auto;
}