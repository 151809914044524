.MuiList-root {
    width: 100%;
}
.entities-collection .collection {
    width: 100%;
}

.entities-collection .collection .collection-item {
    display: flex;
    justify-content: space-between;
}

.entity-content {
    display: flex;
    align-items: center;
    text-align: center;
}

.entity-img {
    height: 5em;
}

.entity-name {
    margin: 0.5em;
}

.actions {
    display: flex;
    align-items: center;
}

.actions .material-icons {
    cursor: pointer;
}

.empty-list-label-container {
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.empty-list-label {
    margin-left: 0.5em;
}