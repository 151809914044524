@import '../constants.scss';

.navbar {
    background-color:$menu_background_color;
    padding: 0 1em;

    .toolbar {
        align-items: stretch;
        display: flex;
        position: relative;    
    }  
}

.nav {
    position: fixed;
    width: 250px;
    background-color: $menu_background_color;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    min-height: 100%;

    &-item {
        font-family: $font_title;
        margin: 0.2em 0;
        color: white !important;
        font-size: 4em;

        &-active {
            & span {
                &::after {
                    transform: scale3d(1, 1, 1);
                    transition: transform 0.3s ease-out;
                }
            }
        }

      
        &:hover::before, &:hover::after {
            width: 100%;
            height: 100%;
        }
    }

    &-logo {
         margin: 10px;
    height: 150px;
    }
}

.default-navbar {
    min-height: 64px;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1;
    opacity: 1;
    transition: opacity 0.5s;
     
    &.hidden {
        opacity: 0;
        transition: opacity 0.5s, visibility 0.5s;
        visibility: hidden;
    }
}