.progressive-underline {
    position: relative;
    vertical-align: middle;
    padding: 0 10px;

    &::after {
        box-sizing: inherit;
        content: '';
        position: absolute;
        bottom: 15px;
        right: 0;
        width: 100%;
        height: 100%;
        transform-origin: center;
        border: 1px solid transparent;
        border-bottom: 3px solid white; // Make borders visible
        transform: scale3d(0,1,1);
        transition: transform 0.2s ease-out;
    }
    
    &::after:hover {
        width: 100%;
        height: 3px;
    }
    &:hover::after {
        transform: scale3d(1,1,1);
        transition: transform 0.3s ease-out;
    }
}