.galleries-list {
    .gallery {
        &-card {
             width: 27em;
            margin: 0.5em;
        }

        &-picture {
            height: 17em;
        }
        &-title {
            font-size: 1.5em;
        }
    }

    .galleries-list-items {
        flex-wrap: wrap;
    }
}